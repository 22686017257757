import clsx from 'clsx';

import Image from 'next/image';
import styles from './styles.module.scss';

interface CompanyItemProps {
  name: string;
  image: string;
  applyGrayScaleFilter?: boolean;
}

const COMPANY_ITEMS_WITHOUT_PADDING = ['alares', 'sjcc'];

export function CompanyItem({
  name,
  image,
  applyGrayScaleFilter,
}: CompanyItemProps) {
  return (
    <div
      className={clsx(
        'flex h-[75px] w-[135px] items-center justify-center rounded-[15px] bg-text-white-positive',
        !COMPANY_ITEMS_WITHOUT_PADDING.includes(name.toLowerCase()) && 'p-3',
        styles.item,
        applyGrayScaleFilter && styles.grayScale
      )}
    >
      <div className="relative h-full w-full">
        <Image
          fill
          style={{
            objectFit: 'contain',
          }}
          sizes="87px"
          alt={name}
          src={image}
          className="block max-h-full max-w-full"
        />
      </div>
    </div>
  );
}
