import { format } from 'date-fns';
import { language } from 'i18n';

import { enUS as en } from 'date-fns/locale/en-US';
import { es } from 'date-fns/locale/es';
import { ptBR as pt } from 'date-fns/locale/pt-BR';

export const LOCALES = {
  en,
  es,
  pt,
};

export class Formatter {
  static documentMask = (v: string) => {
    v = v.replace(/\D/g, '');

    if (v.length <= 11) {
      v = v.replace(/(\d{3})(\d)/, '$1.$2');
      v = v.replace(/(\d{3})(\d)/, '$1.$2');
      v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    } else {
      v = v.replace(/^(\d{2})(\d)/, '$1.$2');
      v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
      v = v.replace(/\.(\d{3})(\d)/, '.$1/$2');
      v = v.replace(/(\d{4})(\d)/, '$1-$2');
    }

    return v;
  };

  static phoneMask = (value: string) => {
    if (!value) return '';
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d{2})(\d)/, '($1) $2');
    value = value.replace(/(\d)(\d{4})$/, '$1-$2');
    return value;
  };

  static removeSpeciaCharacters = (value: string) => {
    const newValue = value.replace(/\.|-/gm, '');
    return newValue;
  };

  static normalizePhone(phone: string) {
    return phone.replace(/[^0-9]/g, '');
  }
}

export const QUANTIFIERS = {
  en: {
    K: {
      singular: 'thousand',
      plural: 'thousand',
    },
    M: {
      singular: 'million',
      plural: 'million',
    },
    B: {
      singular: 'billion',
      plural: 'billion',
    },
  },
  pt: {
    K: {
      singular: 'mil',
      plural: 'mil',
    },
    M: {
      singular: 'milhão',
      plural: 'milhões',
    },
    B: {
      singular: 'bilhão',
      plural: 'bilhões',
    },
  },
  es: {
    K: {
      singular: 'mil',
      plural: 'mil',
    },
    M: {
      singular: 'millón',
      plural: 'millones',
    },
    B: {
      singular: 'billón',
      plural: 'billones',
    },
  },
};

const removeZeroFloatingPoint = (value: string) => {
  if (value.includes('.')) {
    const [integer, floating] = value.split('.');

    if (floating === '0') return integer;
  }

  return value;
};

export const formatBigNumber = (
  number: number,
  language?: language,
  floatingPoints: number = 1
) => {
  const isLanguageValid = Object.keys(QUANTIFIERS).includes(language ?? '');

  const lang = isLanguageValid ? (language as language) : 'pt';

  if (number < 1e3) return [removeZeroFloatingPoint(number.toString()), ''];

  if (number >= 1e3 && number < 1e6) {
    const quantifier = QUANTIFIERS[lang].K;

    const isPlural = number >= 2e3;

    return [
      removeZeroFloatingPoint((number / 1e3).toFixed(floatingPoints)),
      isPlural ? quantifier.plural : quantifier.singular,
    ];
  }
  if (number >= 1e6 && number < 1e9) {
    const quantifier = QUANTIFIERS[lang].M;

    const isPlural = number >= 2e6;

    return [
      removeZeroFloatingPoint((number / 1e6).toFixed(floatingPoints)),
      isPlural ? quantifier.plural : quantifier.singular,
    ];
  }
  if (number >= 1e9 && number < 1e12) {
    const quantifier = QUANTIFIERS[lang].B;

    const isPlural = number >= 2e9;

    return [
      removeZeroFloatingPoint((number / 1e9).toFixed(floatingPoints)),
      isPlural ? quantifier.plural : quantifier.singular,
    ];
  }

  return [removeZeroFloatingPoint(number.toString()), ''];
};

export const formatDate = (
  date: Date,
  formatStr: string,
  language: language
) => {
  return format(date, formatStr, {
    locale: LOCALES[language],
  });
};
