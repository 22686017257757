'use client';

import { Button } from '@components/new/button';
import { sendGAEvent } from '@utils/ga';
import { language } from 'i18n';
import { useTranslation } from 'i18n/client';

interface FormatsHomeSectionButtonProps {
  lng: language;
}

export function FormatsHomeSectionButton({
  lng,
}: FormatsHomeSectionButtonProps) {
  const { t } = useTranslation(lng, 'home');

  const onClick = () => {
    sendGAEvent('productfeatures_page_click');
  };

  return null;

  return (
    <Button
      onClick={onClick}
      variant="outlined"
      className="px-6 py-3 md:px-8 md:py-4"
    >
      {t('formats.discover_features')}
    </Button>
  );
}
