export function Minibook2(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.1606 2.67937C21.1606 2.22017 20.7183 1.89195 20.2798 2.02444L6.19393 6.25788C6.19392 6.25788 6.19394 6.25788 6.19393 6.25788C5.57912 6.4427 5.10145 6.89768 4.8753 7.46973H21.1606V2.67937ZM22.5297 7.46973V2.67937C22.5297 1.30422 21.2036 0.315478 19.8843 0.713742C19.8842 0.713797 19.8845 0.713687 19.8843 0.713742L5.79985 4.94676C4.39427 5.36926 3.4187 6.63898 3.36512 8.09745C3.36358 8.11618 3.36279 8.13513 3.36279 8.15426C3.36279 8.1615 3.36291 8.16872 3.36313 8.17591C3.36291 8.19195 3.36279 8.208 3.36279 8.22408V25.9521C3.36279 27.843 4.89458 29.3748 6.78545 29.3748H23.2142C25.1051 29.3748 26.6369 27.843 26.6369 25.9521V10.8924C26.6369 9.00152 25.1051 7.46973 23.2142 7.46973H22.5297ZM4.73186 8.83879V25.9521C4.73186 27.0869 5.65069 28.0057 6.78545 28.0057H23.2142C24.349 28.0057 25.2678 27.0869 25.2678 25.9521V10.8924C25.2678 9.75763 24.349 8.83879 23.2142 8.83879H4.73186Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5398 21.1886C17.8285 21.1012 18.1404 21.2131 18.3077 21.4641L19.7917 23.6901V21.8438C19.7917 21.4657 20.0982 21.1592 20.4762 21.1592C20.8543 21.1592 21.1608 21.4657 21.1608 21.8438V25.951C21.1608 26.2526 20.9633 26.5187 20.6746 26.6061C20.3859 26.6935 20.074 26.5817 19.9067 26.3307L18.4227 24.1046V25.951C18.4227 26.329 18.1162 26.6355 17.7381 26.6355C17.3601 26.6355 17.0536 26.329 17.0536 25.951V21.8438C17.0536 21.5421 17.251 21.276 17.5398 21.1886ZM6.52365 21.2113C6.77944 21.1054 7.07387 21.164 7.26964 21.3597L9.52373 23.6138L11.7778 21.3597C11.9736 21.164 12.268 21.1054 12.5238 21.2113C12.7796 21.3173 12.9464 21.5669 12.9464 21.8438V25.951C12.9464 26.329 12.6399 26.6355 12.2619 26.6355C11.8838 26.6355 11.5773 26.329 11.5773 25.951V23.4964L10.0078 25.0659C9.74045 25.3333 9.30702 25.3333 9.0397 25.0659L7.47014 23.4964V25.951C7.47014 26.329 7.16366 26.6355 6.78561 26.6355C6.40755 26.6355 6.10107 26.329 6.10107 25.951V21.8438C6.10107 21.5669 6.26786 21.3173 6.52365 21.2113ZM23.2144 21.1592C23.5924 21.1592 23.8989 21.4657 23.8989 21.8438V25.951C23.8989 26.329 23.5924 26.6355 23.2144 26.6355C22.8363 26.6355 22.5298 26.329 22.5298 25.951V21.8438C22.5298 21.4657 22.8363 21.1592 23.2144 21.1592ZM15 21.1592C15.378 21.1592 15.6845 21.4657 15.6845 21.8438V25.951C15.6845 26.329 15.378 26.6355 15 26.6355C14.6219 26.6355 14.3155 26.329 14.3155 25.951V21.8438C14.3155 21.4657 14.6219 21.1592 15 21.1592Z"
        fill="currentColor"
      />
    </svg>
  );
}
