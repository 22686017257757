'use client';

import { useKeenSlider } from 'keen-slider/react';

import { CompanyItem } from '@components/companyItem';
import clsx from 'clsx';
import 'keen-slider/keen-slider.min.css';
import styles from './styles.module.scss';

const PUBLISHERS = [
  { name: 'Gente Editora', image: '/img/publishers/gente.svg' },
  { name: 'Grupo Planeta', image: '/img/publishers/grupo-planeta.svg' },
  { name: 'Alta Books Editora', image: '/img/publishers/alta-books.svg' },
  {
    name: 'Grupo Editorial Record',
    image: '/img/publishers/grupo-editorial-record.svg',
  },
  {
    name: 'Grupo Companhia das Letras',
    image: '/img/publishers/companhia-das-letras.svg',
  },
  { name: 'Globo Livros', image: '/img/publishers/globo-livros.svg' },
  { name: 'Rocco', image: '/img/publishers/rocco.svg' },
  { name: 'Aleph', image: '/img/publishers/aleph.svg' },
  { name: 'Global Editora', image: '/img/publishers/global.svg' },
  { name: 'Buzz', image: '/img/publishers/buzz.svg' },
  { name: 'Jovem Nerd', image: '/img/publishers/jovem-nerd.svg' },
  { name: 'Lura', image: '/img/publishers/lura.svg' },
  { name: 'Nacional', image: '/img/publishers/nacional.svg' },
  {
    name: 'Editora Contexto',
    image: '/img/publishers/editora-contexto.svg',
  },
  {
    name: 'Labrador Editora',
    image: '/img/publishers/labrador-editora.svg',
  },
  {
    name: 'Arqueiro',
    image: '/img/publishers/arqueiro.svg',
  },
  {
    name: 'Sextante',
    image: '/img/publishers/sextante.svg',
  },
];

const ANIMATION = { duration: 20000, easing: (t: number) => t };

export function PublishersCarousel() {
  const [ref] = useKeenSlider<HTMLDivElement>({
    loop: true,
    mode: 'free',
    slides: {
      spacing: 18,
      perView: 'auto',
    },
    created(s) {
      s.moveToIdx(5, true, ANIMATION);
    },
    updated(s) {
      s.moveToIdx(s.track.details.abs + 5, true, ANIMATION);
    },
    animationEnded(s) {
      s.moveToIdx(s.track.details.abs + 5, true, ANIMATION);
    },
  });

  return (
    <div ref={ref} className={clsx('keen-slider mt-7 md:mt-10', styles.slider)}>
      {PUBLISHERS.map((publisher, index) => (
        <div
          key={index}
          className={clsx(
            styles.slide,
            'keen-slider__slide w-[135px] min-w-[135px] max-w-[135px] rounded-[15px]'
          )}
        >
          <CompanyItem name={publisher.name} image={publisher.image} />
        </div>
      ))}
    </div>
  );
}
