'use client';

import { useKeenSlider } from 'keen-slider/react';

import { BookItem } from '@components/bookItem';

import 'keen-slider/keen-slider.min.css';

const ANIMATION = { duration: 15000, easing: (t: number) => t };

interface ReleasesCarouselProps {
  books: Array<{
    title: string;
    cover: string;
    formats: string[];
  }>;
}

export function ReleasesCarousel({ books }: ReleasesCarouselProps) {
  const [ref] = useKeenSlider<HTMLDivElement>({
    rtl: true,
    loop: true,
    mode: 'free',
    slides: {
      spacing: 24,
      perView: 'auto',
    },
    breakpoints: {
      '(min-width: 769px)': {
        slides: {
          spacing: 32,
          perView: 'auto',
        },
      },
    },
    created(s) {
      s.moveToIdx(5, true, ANIMATION);
    },
    updated(s) {
      s.moveToIdx(s.track.details.abs + 5, true, ANIMATION);
    },
    animationEnded(s) {
      s.moveToIdx(s.track.details.abs + 5, true, ANIMATION);
    },
  });

  return (
    <div ref={ref} className="keen-slider books-carousel">
      {books.map((book, index) => (
        <div
          key={index}
          className="keen-slider__slide w-[140px] min-w-[140px] max-w-[140px] md:w-[187px] md:min-w-[187px] md:max-w-[187px]"
        >
          <BookItem
            title={book.title}
            cover={book.cover}
            formats={book.formats}
          />
        </div>
      ))}
    </div>
  );
}
