/* eslint-disable react/button-has-type */
import clsx from 'clsx';
import Link from 'next/link';
import {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  CSSProperties,
  ReactNode,
} from 'react';

type ButtonHTMLProps = ButtonHTMLAttributes<HTMLButtonElement>;

type AnchorHTMLProps = AnchorHTMLAttributes<HTMLAnchorElement>;

interface BaseProps {
  active?: boolean;
  variant?: 'contained' | 'outlined' | 'text';
  width?: number;
  maxWidth?: boolean;
  className?: string;
  scheme?: 'skeelo' | 'skoob';
}

interface LinkProps extends BaseProps, AnchorHTMLProps {
  children: ReactNode;
  href: string;
  type?: never;
}

interface ButtonProps extends BaseProps, ButtonHTMLProps {
  children: ReactNode;
  href?: never;
  type?: 'button' | 'submit' | 'reset';
}

type Props = LinkProps | ButtonProps;

const DEFAULT_CLASS_NAME =
  'flex duration-400 font-bold rounded-full border-2 transition ease-in-out';

export function Button({
  variant = 'contained',
  width,
  maxWidth,
  children,
  className,
  href,
  type,
  scheme = 'skeelo',
  ...props
}: Props) {
  const VARIANT_COLORS = {
    contained: clsx(
      'text-text-white-positive disabled:border-paper-400 disabled:bg-paper-400 disabled:opacity-50 disabled:hover:bg-paper-400',
      scheme === 'skeelo'
        ? 'active:border-brand-300-positive border-brand-500-positive bg-brand-500-positive hover:border-brand-700 hover:bg-brand-700'
        : 'border-skoob-main bg-skoob-main hover:border-skoob-dark hover:bg-skoob-dark active:border-skoob-main'
    ),
    outlined: clsx(
      'active:bg-white bg-paper-white disabled:border-paper-400 disabled:text-paper-400 disabled:opacity-50 disabled:hover:bg-paper-white',
      scheme === 'skeelo'
        ? `active:border-brand-300-positive border-brand-500-positive text-brand-500-positive hover:border-brand-500-positive hover:bg-brand-100-positive`
        : `border-skoob-main text-skoob-main hover:border-skoob-main hover:bg-skoob-main active:border-skoob-main`
    ),
    text: clsx(
      'border-paper-white bg-paper-white active:bg-paper-white disabled:border-paper-white disabled:bg-paper-white disabled:text-paper-500 disabled:opacity-50 disabled:hover:bg-paper-white',
      scheme === 'skeelo'
        ? `active:border-brand-300-positive text-brand-500-positive hover:border-brand-100-positive hover:bg-brand-100-positive`
        : `text-skoob-main hover:border-skoob-main hover:bg-skoob-main active:border-skoob-main`
    ),
  };

  const variantColorsStyles = VARIANT_COLORS[variant];

  const style: CSSProperties = {
    minWidth: width && !maxWidth ? `${width}px` : undefined,
  };

  const content = (
    <p className="flex items-center justify-center">{children}</p>
  );

  const classNameString = clsx(
    DEFAULT_CLASS_NAME,
    variantColorsStyles,
    className
  );

  if (href) {
    return (
      <Link
        href={href}
        style={style}
        className={classNameString}
        {...(props as AnchorHTMLProps)}
      >
        {content}
      </Link>
    );
  }

  return (
    <button
      style={style}
      type={type || 'button'}
      className={classNameString}
      {...(props as ButtonProps)}
    >
      {content}
    </button>
  );
}
