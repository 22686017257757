'use client';

import { useEffect, useState } from 'react';

type Breakpoints = 'mobile' | 'tablet' | 'laptop' | 'desktop';

export const useBreakpoint = (): Breakpoints => {
  const [width, setWidth] = useState(0);

  const handleWindowResize = (): void => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    setWidth(window.innerWidth);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  let breakpoint: Breakpoints;

  if (width > 480 && width <= 768) {
    breakpoint = 'tablet';
  } else if (width > 769 && width <= 1024) {
    breakpoint = 'laptop';
  } else if (width > 1024) {
    breakpoint = 'desktop';
  } else {
    breakpoint = 'mobile';
  }

  return breakpoint;
};

export const isMobileDevice = () => {
  const breakpoint = useBreakpoint();
  return breakpoint === 'mobile' || breakpoint === 'tablet';
};
