'use client';

import { Button } from '@components/new/button';
import { sendGAEvent } from '@utils/ga';
import { language } from 'i18n';
import { useTranslation } from 'i18n/client';

interface SkoobHomeSectionButtonProps {
  lng: language;
}

export function SkoobHomeSectionButton({ lng }: SkoobHomeSectionButtonProps) {
  const { t } = useTranslation(lng, 'home');

  const onClick = () => {
    sendGAEvent('skoob_click');
  };

  return (
    <Button
      onClick={onClick}
      variant="contained"
      className="px-6 py-3 md:px-8 md:py-4"
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.skoob.com.br/"
      scheme="skoob"
    >
      {t('skoob.open_skoob')}
    </Button>
  );
}
