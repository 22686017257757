'use client';

import { sendGAEvent } from '@utils/ga';
import {
  ReactNode,
  RefObject,
  createContext,
  createRef,
  useContext,
  useState,
} from 'react';

interface CheckBenefitContextValues {
  iframeRef: RefObject<HTMLIFrameElement>;
  height: number;
  handleUpdateHeight: (newHeight: number) => void;
  handleOpenCheckBenefitAccordion: (eventName?: string) => void;
}

interface CheckBenefitProviderProps {
  children: ReactNode;
}

const CheckBenefitContext = createContext({} as CheckBenefitContextValues);

export function CheckBenefitProvider({ children }: CheckBenefitProviderProps) {
  const iframeRef = createRef<HTMLIFrameElement>();

  const [height, setHeight] = useState(52);

  const handleUpdateHeight = (newHeight: number) => {
    setHeight(newHeight);
  };

  const handleOpenCheckBenefitAccordion = (eventName?: string) => {
    if (
      !iframeRef.current ||
      !iframeRef.current.contentWindow ||
      typeof window === 'undefined'
    )
      return;

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    iframeRef.current.contentWindow.postMessage(
      {
        type: 'open-accordion',
      },
      {
        targetOrigin: '*',
      }
    );

    if (eventName) sendGAEvent(eventName);
  };

  return (
    <CheckBenefitContext.Provider
      value={{
        iframeRef,
        handleOpenCheckBenefitAccordion,
        height,
        handleUpdateHeight,
      }}
    >
      {children}
    </CheckBenefitContext.Provider>
  );
}

export const useCheckBenefit = () => useContext(CheckBenefitContext);
