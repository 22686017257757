'use client';

import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import animationData from 'lotties/sparkles-1.json';
import { useEffect, useRef } from 'react';

export function SparklesOneAnimation() {
  const lottieRef = useRef<LottieRefCurrentProps>(null);

  useEffect(() => {
    lottieRef.current?.play();

    lottieRef.current?.setSpeed(0.5);
  }, [lottieRef?.current]);

  return <Lottie animationData={animationData} lottieRef={lottieRef} loop />;
}
