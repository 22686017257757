'use client';

import { PartnersCarousel } from '@sections/partnersCarousel';
import { GetPartnersResponse } from '@services/types';
import { useEffect, useState } from 'react';

export function HeroCarouselsSection() {
  const [partners, setPartners] = useState<GetPartnersResponse>([]);

  const allPartners =
    partners?.map(partner => ({
      name: partner.name,
      image: `https://assets.skeelo.app/images/${partner.image}`,
    })) ?? [];

  const partnersLength = allPartners.length;

  const halfway = Math.ceil(partnersLength / 2);

  const firstHalf = allPartners.slice(0, halfway);
  const secondHalf = allPartners.slice(halfway, partnersLength);

  useEffect(() => {
    const getPartners = async () => {
      const response = await fetch(
        'https://api.skeelo.app/api/v3/carrier/carousel'
      );

      const data = await response.json();

      setPartners(data);
    };

    getPartners();
  }, []);

  return (
    <>
      <div className="order-3 mb-4 mt-8 min-h-[75px] lg:absolute lg:left-[25px] lg:top-[-80px] lg:z-10 lg:mb-0 lg:mt-0 lg:h-full lg:max-h-[434px] lg:min-h-[auto] lg:max-w-[165px] lg:overflow-x-hidden lg:overflow-y-hidden lg:px-[15px]">
        {firstHalf.length > 0 && <PartnersCarousel partners={firstHalf} />}
      </div>

      <div className="order-4 min-h-[75px] lg:absolute lg:left-[178px] lg:top-[-80px] lg:z-10 lg:h-full lg:max-h-[434px] lg:min-h-[auto] lg:max-w-[165px] lg:overflow-y-hidden lg:px-[15px]">
        {secondHalf.length > 0 && (
          <PartnersCarousel partners={secondHalf} options={{ rtl: true }} />
        )}
      </div>
    </>
  );
}
