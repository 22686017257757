export const fallbackLng = 'pt';
export const languages = [fallbackLng, 'en', 'es'];
export const defaultNS = 'translation';
export const cookieName = 'i18next';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function getOptions(
  lng = fallbackLng,
  ns: string | string[] = defaultNS
) {
  return {
    // debug: true,
    supportedLngs: languages,
    preload: languages,
    fallbackLng,
    lng,
    fallbackNS: defaultNS,
    defaultNS,
    ns,
  };
}
