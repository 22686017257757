'use client';

import { Audiobook3 } from '@components/Icon/Audiobook3';
import { Comeeks2 } from '@components/Icon/Comeeks2';
import { Ebook3 } from '@components/Icon/Ebook3';
import { Minibook2 } from '@components/Icon/Minibook2';
import { isMobileDevice } from '@hooks/useBreakpoint';
import clsx from 'clsx';
import { ButtonHTMLAttributes } from 'react';

export type Format = 'ebooks' | 'audiobooks' | 'minibooks' | 'comicbooks';

const FORMATS_TITLES = {
  ebooks: 'Ebooks',
  audiobooks: 'Audiobooks',
  minibooks: 'Minibooks',
  comicbooks: 'Comeeks',
};

const ICONS = {
  ebooks: Ebook3,
  audiobooks: Audiobook3,
  minibooks: Minibook2,
  comicbooks: Comeeks2,
};

interface FormatButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  format: Format;
  isActive?: boolean;
}

export function FormatButton({ format, isActive, ...rest }: FormatButtonProps) {
  const isMobile = isMobileDevice();

  const title = FORMATS_TITLES[format];

  const Icon = ICONS[format];

  return (
    <button {...rest}>
      <span
        className={clsx(
          'mb-3 flex h-[60px] w-[60px] items-center justify-center rounded-full transition-colors [box-shadow:0px_0px_1.5px_0px_#919E9B29,_0px_3px_9px_0px_#4C57540F,_0px_5.25px_12.75px_0px_#4C575414] md:h-[80px] md:w-[80px] md:[box-shadow:0px_0px_2px_0px_#919E9B29,_0px_4px_12px_0px_#4C57540F,_0px_7px_17px_0px_#4C575414]',
          isActive
            ? 'bg-brand-100-positive text-brand-500-negative'
            : 'bg-text-white-positive text-paper-400'
        )}
      >
        <Icon width={isMobile ? 30 : 40} height={isMobile ? 30 : 40} />
      </span>
      <span
        className={clsx(
          'text-center text-[10px] font-bold md:text-body-micro',
          isActive ? 'text-text-900' : 'text-text-500'
        )}
      >
        {title}
      </span>
    </button>
  );
}
