'use client';

import { Button } from '@components/new/button';
import { sendGAEvent } from '@utils/ga';
import clsx from 'clsx';
import { useInView } from 'framer-motion';
import { ReactNode, useEffect, useRef } from 'react';

interface OpenAppButtonProps {
  children: ReactNode;
  href: string;
  eventName?: string;
  className?: string;
  viewEventName?: string;
}

export function OpenAppButton({
  children,
  href,
  eventName,
  className,
  viewEventName,
}: OpenAppButtonProps) {
  const containerRef = useRef<HTMLDivElement>(null);

  const inView = useInView(containerRef, {
    once: true,
  });

  const onClick = () => {
    if (eventName) sendGAEvent(eventName);
  };

  useEffect(() => {
    if (inView && viewEventName) sendGAEvent(viewEventName);
  }, [inView]);

  return (
    <div ref={containerRef}>
      <Button
        href={href}
        onClick={onClick}
        variant="outlined"
        target="_blank"
        rel="noopener noreferrer"
        className={clsx('px-6 py-3 text-center md:px-8 md:py-4', className)}
      >
        {children}
      </Button>
    </div>
  );
}
