'use client';

import { useKeenSlider } from 'keen-slider/react';

import { BookItem } from '@components/bookItem';

import 'keen-slider/keen-slider.min.css';

const BOOKS = [
  {
    title: '2001: Uma odisséia no espaço',
    cover: '/img/books/1.png',
  },
  {
    title: 'O Poder Da Autorresponsabilidade',
    cover: '/img/books/2.png',
  },
  {
    title: 'O Alquimista',
    cover: '/img/books/3.png',
  },
  {
    title: 'Torto Arado',
    cover: '/img/books/4.png',
  },
  {
    title: 'Mindset: A Nova Psicologia do Sucesso',
    cover: '/img/books/5.png',
  },
  {
    title: 'Rita Lee: uma autobiografia',
    cover: '/img/books/6.png',
  },
  {
    title: 'Antes que o café esfrie',
    cover: '/img/books/7.png',
  },
  {
    title: 'O diário de uma princesa desastrada',
    cover: '/img/books/8.png',
  },
  {
    title: 'Luzes do Norte',
    cover: '/img/books/9.png',
  },
  {
    title: 'A gente mira no amor e acerta na solidão',
    cover: '/img/books/10.png',
  },
  {
    title: 'Onde está Daisy Mason?',
    cover: '/img/books/11.png',
  },
  {
    title: 'Hábitos Atômicos',
    cover: '/img/books/12.png',
  },
];

const ANIMATION = { duration: 15000, easing: (t: number) => t };

export function BooksCarousel() {
  const [ref] = useKeenSlider<HTMLDivElement>({
    loop: true,
    mode: 'free',
    slides: {
      spacing: 24,
      perView: 'auto',
    },
    breakpoints: {
      '(min-width: 769px)': {
        slides: {
          spacing: 32,
          perView: 'auto',
        },
      },
    },
    created(s) {
      s.moveToIdx(-5, true, ANIMATION);
    },
    updated(s) {
      s.moveToIdx(s.track.details.abs - 5, true, ANIMATION);
    },
    animationEnded(s) {
      s.moveToIdx(s.track.details.abs - 5, true, ANIMATION);
    },
  });

  return (
    <div ref={ref} className="keen-slider books-carousel">
      {BOOKS.map((book, index) => (
        <div
          key={index}
          className="keen-slider__slide w-[140px] min-w-[140px] max-w-[140px] md:w-[187px] md:min-w-[187px] md:max-w-[187px]"
        >
          <BookItem title={book.title} cover={book.cover} />
        </div>
      ))}
    </div>
  );
}
