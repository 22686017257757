/* eslint-disable @typescript-eslint/no-explicit-any */
declare global {
  interface Window {
    gtag: (...args: any[]) => void;
  }
}

export const sendGAEvent = (
  action: string,
  options?: Record<string, any>
): void => {
  if (typeof window === 'undefined' || !window?.gtag) return;

  if (options != null) {
    window.gtag('event', action, options);

    return;
  }

  window.gtag('event', action);
};
