export function Ebook3(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.1875 6.25C7.1875 5.38705 7.88705 4.6875 8.75 4.6875H9.68753V5.96933C9.64028 6.83859 10.2856 7.59621 11.1573 7.6829C11.1881 7.68597 11.219 7.6875 11.25 7.6875H16.25C16.281 7.6875 16.312 7.68597 16.3428 7.6829C17.2145 7.59621 17.8598 6.83859 17.8125 5.96933V4.6875H18.75C19.6129 4.6875 20.3125 5.38705 20.3125 6.25V11.25C20.3125 11.7678 20.7322 12.1875 21.25 12.1875C21.7678 12.1875 22.1875 11.7678 22.1875 11.25V6.25C22.1875 4.35152 20.6485 2.8125 18.75 2.8125H16.875H10.625H8.75C6.85152 2.8125 5.3125 4.35152 5.3125 6.25V23.75C5.3125 25.6485 6.85152 27.1875 8.75 27.1875H15C15.5178 27.1875 15.9375 26.7678 15.9375 26.25C15.9375 25.7322 15.5178 25.3125 15 25.3125H8.75C7.88705 25.3125 7.1875 24.6129 7.1875 23.75V6.25ZM11.5625 5.8125V4.6875L15.9375 4.6875V5.8125H11.5625Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9981 15.2404C18.7385 14.607 17.2986 14.2272 15.9 14.1721C15.3886 14.1419 14.9224 14.3291 14.5866 14.6723C14.2599 15.006 14.096 15.4436 14.0667 15.8752C14.0641 15.9131 14.0641 15.9929 14.0641 16.0354L14.064 16.0512L14.0625 18.4877V22.1132C14.0625 22.5831 14.291 23.01 14.5459 23.2987C14.8116 23.5997 15.2437 23.9063 15.8056 23.9345C15.8174 23.9351 15.8291 23.9355 15.8409 23.9356C17.1372 23.9516 18.4055 24.314 19.5146 24.9852C19.813 25.1658 20.187 25.1658 20.4854 24.9852C21.5945 24.314 22.8628 23.9516 24.159 23.9356C24.1708 23.9355 24.1826 23.9351 24.1944 23.9345C25.162 23.886 25.9252 23.0937 25.9375 22.1251V15.9413C25.9393 15.5983 25.878 15.1575 25.5977 14.7817C25.2814 14.3577 24.8093 14.1762 24.329 14.173C24.2957 14.1728 24.2185 14.1768 24.1654 14.1797C24.117 14.174 24.0675 14.1719 24.0173 14.1739C22.6078 14.2287 21.2379 14.6083 19.9981 15.2404ZM20.9376 16.8667C21.8221 16.4288 22.7746 16.1529 23.7446 16.0691C23.7646 16.0706 23.7809 16.0711 23.7899 16.0714C23.8216 16.0722 23.8515 16.0715 23.8627 16.0712C23.8943 16.0704 23.934 16.0688 23.9718 16.067L24.0625 16.0627V22.062C22.9888 22.0838 21.9314 22.2946 20.9376 22.6807V16.8667ZM19.0626 16.8694C18.0959 16.3992 16.9984 16.1092 15.939 16.051L15.939 16.0553L15.9375 18.456L15.9375 18.4856L15.9375 18.4877L15.9375 22.04C15.9417 22.046 15.9465 22.0521 15.9516 22.0579L15.9557 22.0623C17.0232 22.0862 18.0743 22.2968 19.0626 22.6807V16.8694Z"
        fill="currentColor"
      />
    </svg>
  );
}
