import clsx from 'clsx';

import Image from 'next/image';
import styles from './styles.module.scss';

interface BookItemProps {
  title: string;
  cover: string;
  formats?: string[];
}

export function BookItem({ title, cover, formats }: BookItemProps) {
  const hasFormats = !!formats?.length;

  return (
    <div className={clsx(styles.wrapper)}>
      <Image
        src={cover}
        alt={title}
        width={186}
        height={280}
        className={styles.cover}
      />

      {hasFormats && (
        <div className={styles.formats}>
          {formats.map((format, index) => (
            <span className={styles.format} key={index}>
              {format}
            </span>
          ))}
        </div>
      )}
    </div>
  );
}
