export function Audiobook3(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_702_2733)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.8125 15C2.8125 8.26848 8.26848 2.8125 15 2.8125C21.7315 2.8125 27.1875 8.26848 27.1875 15V21.25C27.1875 21.7678 26.7678 22.1875 26.25 22.1875C25.7322 22.1875 25.3125 21.7678 25.3125 21.25V15C25.3125 9.30402 20.696 4.6875 15 4.6875C9.30402 4.6875 4.6875 9.30402 4.6875 15V21.25C4.6875 21.7678 4.26777 22.1875 3.75 22.1875C3.23223 22.1875 2.8125 21.7678 2.8125 21.25V15Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.75 17.1874C8.23277 17.1874 7.8125 17.6076 7.8125 18.1249V23.1249C7.8125 23.6421 8.23277 24.0624 8.75 24.0624H9.375C9.54723 24.0624 9.6875 23.9221 9.6875 23.7499V17.4999C9.6875 17.3276 9.54723 17.1874 9.375 17.1874H8.75ZM5.9375 18.1249C5.9375 16.5721 7.19723 15.3124 8.75 15.3124H9.375C10.5828 15.3124 11.5625 16.2921 11.5625 17.4999V23.7499C11.5625 24.9576 10.5828 25.9374 9.375 25.9374H8.75C7.19723 25.9374 5.9375 24.6776 5.9375 23.1249V18.1249Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.625 17.1874C20.4528 17.1874 20.3125 17.3276 20.3125 17.4999V23.7499C20.3125 23.9221 20.4528 24.0624 20.625 24.0624H21.25C21.7672 24.0624 22.1875 23.6421 22.1875 23.1249V18.1249C22.1875 17.6076 21.7672 17.1874 21.25 17.1874H20.625ZM18.4375 17.4999C18.4375 16.2921 19.4172 15.3124 20.625 15.3124H21.25C22.8028 15.3124 24.0625 16.5721 24.0625 18.1249V23.1249C24.0625 24.6776 22.8028 25.9374 21.25 25.9374H20.625C19.4172 25.9374 18.4375 24.9576 18.4375 23.7499V17.4999Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_702_2733">
          <rect width="30" height="30" fill="none" />
        </clipPath>
      </defs>
    </svg>
  );
}
