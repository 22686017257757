'use client';

import { Button } from '@components/new/button';
import { useCheckBenefit } from '@hooks/useCheckBenefit';
import clsx from 'clsx';
import { ReactNode } from 'react';

interface CheckBenefitButtonProps {
  children: ReactNode;
  eventName?: string;
  className?: string;
}

export function CheckBenefitButton({
  children,
  eventName,
  className,
}: CheckBenefitButtonProps) {
  const { handleOpenCheckBenefitAccordion } = useCheckBenefit();

  const onClick = () => {
    handleOpenCheckBenefitAccordion(eventName);
  };

  return (
    <Button
      onClick={onClick}
      type="button"
      variant="contained"
      className={clsx('px-6 py-3 md:px-8 md:py-4', className)}
    >
      {children}
    </Button>
  );
}
